<template>
  <div data-cy="add-rest-integration">
    <v-row align="baseline">
      <v-col>
        <v-textarea
          v-model="value.onboardingUrl"
          :outlined="true"
          :disabled="true"
          :label="'Onboarding-Url *'"
          data-cy="tf-onboarding-url" />
        <v-textarea
          v-model="value.fetchUrl"
          :outlined="true"
          :disabled="true"
          :label="'Fetch-Url *'"
          data-cy="tf-fetch-url"
      /></v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: function () {
        return {
          onboardingUrl:
            "https://www.pegelonline.wsv.de/webservices/rest-api/v2/stations",
          fetchUrl:
            "https://www.pegelonline.wsv.de/webservices/rest-api/v2/stations/${device.deviceId}/W/currentmeasurement.json",
        };
      },
    },
  },
  mounted() {
    this.$emit("input", this.value);
  },
  watch: {
    value: {
      handler() {
        this.$emit("input", this.value);
      },
      deep: true,
    },
  },
};
</script>
