<template>
  <BaseContentLoader :isLoading="isLoading">
    <template slot="content">
      <BaseTabDialog
        :subHeaders="subHeaders"
        :tabHeaders="tabHeaders"
        @confirm="addIntegration()"
        @cancel="$router.push('/dad/integrations')"
      >
        <template slot="tab-item-0">
          <v-col cols="6">
            <v-text-field
              v-model="name"
              :label="$t('_Name') + ' *'"
              :rules="nameRules"
              data-cy="tf-name"
            ></v-text-field>
          </v-col>
        </template>

        <template slot="tab-item-1">
          <v-col cols="8">
            <v-select
              v-model="type"
              :items="integrationTypes"
              :label="$t('_IntegrationType') + ' *'"
              @input="infoList = undefined"
              :rules="integrationTypeRules"
              data-cy="sel-type"
            />
            <AddChirpstackIntegration
              v-if="type === 'CHIRPSTACK'"
              v-model="infoList"
            />
            <AddMQTTIntegration
              v-else-if="type === 'MQTT'"
              v-model="infoList"
            />
            <AddRESTIntegration
              v-else-if="type === 'REST'"
              v-model="infoList"
            />
            <AddSwisscomIntegration
              v-else-if="type === 'SWISSCOM'"
              v-model="infoList"
            />
            <!-- MPDT210068-3211-->
            <AddDigimondoIntegration
              v-else-if="type === 'DIGIMONDO'"
              v-model="infoList"
            />
            <AddWebhookDigimondoIntegration
              v-else-if="type === 'WEBHOOK_DIGIMONDO'"
              v-model="infoList"
            />
            <AddElementIntegration
              v-else-if="type === 'ELEMENT'"
              v-model="infoList"
            />
            <AddHttpPollIntegration
              v-else-if="type === 'HTTP_POLL'"
              v-model="infoList"
            />
          </v-col>
        </template>
      </BaseTabDialog>
    </template>
  </BaseContentLoader>
</template>

<script>
import { mapGetters } from "vuex";
import AddChirpstackIntegration from "./add-integration/AddChirpstackIntegration.vue";
import AddMQTTIntegration from "./add-integration/AddMQTTIntegration.vue";
import AddRESTIntegration from "./add-integration/AddRESTIntegration.vue";
import AddSwisscomIntegration from "./add-integration/AddSwisscomIntegration.vue";
// MPDT210068-3211
import AddDigimondoIntegration from "./add-integration/AddDigimondoIntegration.vue";
import AddElementIntegration from "./add-integration/AddElementIntegration";
import AddWebhookDigimondoIntegration from "@/views/services/dad/integrations/add-integration/AddWebhookDigimondoIntegration";
import AddHttpPollIntegration from "@/views/services/dad/integrations/add-integration/AddHttpPollIntegration.vue";

import { delay } from "@smart-city-plattform/vue-component-library/src/helper/helper";
import {
  nameRules,
  integrationTypeRules,
} from "@smart-city-plattform/vue-component-library/src/helper/rules";

export default {
  components: {
    AddHttpPollIntegration,
    AddSwisscomIntegration,
    AddChirpstackIntegration,
    AddMQTTIntegration,
    AddRESTIntegration,
    // MPDT210068-3211
    AddDigimondoIntegration,
    AddWebhookDigimondoIntegration,
    AddElementIntegration,
  },
  data() {
    return {
      isLoading: true,
      name: "",
      type: undefined,
      infoList: undefined,
    };
  },
  mounted() {
    this.isLoading = false;
  },
  computed: {
    ...mapGetters(["getCurrentOrganization"]),
    subHeaders() {
      return [
        this.$t("__CFA1_AddIntegration"),
        this.$t("__CFA2_AddIntegration"),
      ];
    },
    tabHeaders() {
      return [this.$t("_Name"), this.$t("_IntegrationType")];
    },
    nameRules() {
      return nameRules();
    },
    integrationTypeRules() {
      return integrationTypeRules();
    },
    integrationTypes: () => {
      return [
        { value: "CHIRPSTACK", text: "Chirpstack (LoRaWAN)" },
        { value: "SWISSCOM", text: "Swisscom (LoRaWAN)" },
        { value: "MQTT", text: "MQTT Client" },
        { value: "REST", text: "REST Client" },
        { value: "WEBHOOK_DIGIMONDO", text: "Webhook (Digimondo)" },
        // MPDT210068-3211: add DIGIMONDO / DIGIMONDO (LoRaWAN)
        { value: "DIGIMONDO", text: "Digimondo (LoRaWAN)" },
        { value: "ELEMENT", text: "Element IoT (LoRaWAN)" },
        { value: "HTTP_POLL", text: "HTTP-Poll" },
      ];
    },
  },
  methods: {
    async addIntegration() {
      this.isLoading = true;
      try {
        var integration = {
          name: this.name,
          type: this.type,
          organizationId: this.getCurrentOrganization.id,
          details: { infoList: this.infoList },
        };
        await delay(500);
        await this.$store.dispatch("createIntegration", integration);
        this.$router.push("/dad/integrations");
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
  },
};
</script>
