<template>
  <BaseContentLoader :title="title" :isLoading="isLoading">
    <template slot="content">
      <v-form v-model="isValid" ref="form">
        <BaseSummary
          v-model="newIntegration"
          :items="summaryItems"
          isEditable
        ></BaseSummary>
      </v-form>
      <v-row class="pt-8">
        <v-spacer></v-spacer>
        <v-btn
          class="mx-3"
          @click="undoChanges()"
          :disabled="disabledUndo"
          data-cy="btn-undo"
        >
          <v-icon left>mdi-undo</v-icon>
          {{ $t("_UndoChanges") }}
        </v-btn>
        <v-btn
          class="mx-3"
          color="primary"
          @click="saveChanges()"
          :disabled="disabledSave"
          data-cy="btn-save"
          >{{ $t("_SaveChanges") }}
        </v-btn>
      </v-row>
    </template>
  </BaseContentLoader>
</template>

<script>
import { mapGetters } from "vuex";
import { cloneDeep, isEqual } from "lodash";
import {
  apiKeyRules,
  nameRules,
} from "@smart-city-plattform/vue-component-library/src/helper/rules";
import { delay } from "@smart-city-plattform/vue-component-library/src/helper/helper";

export default {
  data() {
    return {
      isLoading: true,
      isValid: false,
      newIntegration: undefined,
    };
  },
  async mounted() {
    try {
      this.newIntegration = cloneDeep(
        await this.$store.dispatch("loadIntegration", this.$route.query.id),
      );
    } catch (error) {
      console.log(error);
    }
    this.isLoading = false;
  },
  computed: {
    ...mapGetters([
      "getIntegrationById",
      "isCurrentOrganizationsAdmin",
      "isSystemAdmin",
    ]),
    title() {
      if (this.integration == undefined) return;
      return this.$t("_Integration") + ": " + this.integration.name;
    },
    integration() {
      return this.getIntegrationById(this.$route.query.id);
    },
    integrationTypes: () => {
      return [
        { value: "CHIRPSTACK", text: "Chirpstack (LoRaWAN)" },
        { value: "SWISSCOM", text: "Swisscom (LoRaWAN)" },
        { value: "MQTT", text: "MQTT Client" },
        { value: "REST", text: "REST Client" },
        { value: "WEBHOOK_DIGIMONDO", text: "Webhook (Digimondo)" },
        // MPDT210068-3211: add DIGIMONDO / DIGIMONDO (LoRaWAN)
        { value: "DIGIMONDO", text: "Digimondo (LoRaWAN)" },
        { value: "ELEMENT", text: "Element IoT (LoRaWAN)" },
        { value: "HTTP_POLL", text: "HTTP-Poll" },
      ];
    },
    nameRules() {
      return nameRules();
    },
    disabledSave() {
      if (!(this.isCurrentOrganizationsAdmin || this.isSystemAdmin))
        return true;
      if (this.newIntegration == undefined) return true;
      if (!this.isValid) return true;
      return isEqual(this.newIntegration, this.integration);
    },
    disabledUndo() {
      if (!(this.isCurrentOrganizationsAdmin || this.isSystemAdmin))
        return true;
      if (this.newIntegration == undefined) return true;

      return isEqual(this.newIntegration, this.integration);
    },

    summaryItems() {
      if (this.newIntegration == undefined) return [];

      var summaryItems = [
        {
          key: "id",
          name: "ID",
          type: "text-field",
          props: {
            readonly: true,
            disabled: true,
          },
        },
        {
          key: "name",
          name: this.$t("_Name"),
          type: "text-field",
          props: {
            rules: nameRules,
            readonly: !(this.isCurrentOrganizationsAdmin || this.isSystemAdmin),
          },
        },
        {
          key: "type",
          name: this.$t("_Type"),
          type: "select",
          props: {
            items: this.integrationTypes,
            disabled: true,
          },
        },
      ];

      switch (this.integration.type) {
        case "CHIRPSTACK":
          summaryItems.push({
            key: "details.infoList.applicationId",
            name: "Application ID",
            type: "text-field",
            props: {
              readonly: !(
                this.isCurrentOrganizationsAdmin || this.isSystemAdmin
              ),
            },
          });
          summaryItems.push({
            key: "details.infoList.url",
            name: this.$t("_Url"),
            type: "text-field",
            props: {
              readonly: !(
                this.isCurrentOrganizationsAdmin || this.isSystemAdmin
              ),
            },
          });
          summaryItems.push({
            key: "details.infoList.apiToken",
            name: "API-KEY",
            type: "textarea",
            props: {
              readonly: !(
                this.isCurrentOrganizationsAdmin || this.isSystemAdmin
              ),
            },
          });
          break;
        case "DIGIMONDO":
          summaryItems.push({
            key: "details.infoList.organizationId",
            name: "DIGIMONDO Organization ID",
            props: {
              readonly: !(
                this.isCurrentOrganizationsAdmin || this.isSystemAdmin
              ),
            },
          });
          summaryItems.push({
            key: "details.infoList.url",
            name: this.$t("_Url"),
            props: {
              readonly: !(
                this.isCurrentOrganizationsAdmin || this.isSystemAdmin
              ),
            },
          });
          break;
        case "SWISSCOM":
          summaryItems.push({
            key: "details.infoList.as_id",
            name: "AS ID",
            type: "text-field",
            props: {
              readonly: !(
                this.isCurrentOrganizationsAdmin || this.isSystemAdmin
              ),
            },
          });
          summaryItems.push({
            key: "details.infoList.as_key",
            name: "AS KEY",
            type: "text-field",
            props: {
              readonly: !(
                this.isCurrentOrganizationsAdmin || this.isSystemAdmin
              ),
            },
          });
          summaryItems.push({
            key: "details.infoList.url",
            name: this.$t("_Url"),
            type: "text-field",
            props: {
              readonly: true,
            },
          });
          summaryItems.push({
            key: "details.infoList.connectivityPlanId",
            name: "Connectivity Plan Id",
            type: "text-field",
            props: {
              readonly: !(
                this.isCurrentOrganizationsAdmin || this.isSystemAdmin
              ),
            },
          });
          summaryItems.push({
            key: "details.infoList.apiToken",
            name: "API-KEY",
            type: "textarea",
            props: {
              readonly: !(
                this.isCurrentOrganizationsAdmin || this.isSystemAdmin
              ),
            },
          });
          break;
        case "ELEMENT":
          summaryItems.push({
            key: "details.infoList.url",
            type: "text-field",
            name: this.$t("_Url"),
            props: {
              readonly: !(
                this.isCurrentOrganizationsAdmin || this.isSystemAdmin
              ),
            },
          });
          summaryItems.push({
            key: "details.infoList.apiToken",
            name: "API-KEY",
            type: "textarea",
            props: {
              readonly: !(
                this.isCurrentOrganizationsAdmin || this.isSystemAdmin
              ),
            },
          });
          summaryItems.push({
            key: "details.infoList.folder",
            name: "Folder-Id",
            type: "text-field",
            props: {
              noDataText: this.$t("_APIKeyAndRootFirst"),
              itemText: "id",
              itemValue: "id",
              dataCy: "tf-folder",
              rules: apiKeyRules,
              readonly: true,
            },
          });
          summaryItems.push({
            key: "details.infoList.driverInstance",
            name: "Driver Instance-Id",
            type: "text-field",
            props: {
              noDataText: this.$t("_APIKeyFirst"),
              itemText: "id",
              itemValue: "id",
              dataCy: "tf-root-folder",
              rules: apiKeyRules,
              readonly: !(
                this.isCurrentOrganizationsAdmin || this.isSystemAdmin
              ),
            },
          });
          break;
        case "HTTP_POLL":
          summaryItems.push({
            key: "details.infoList.onboardingUrl",
            type: "textarea",
            name: "Onboarding-Url",
            props: {
              disabled: true,
            },
          });
          summaryItems.push({
            key: "details.infoList.fetchUrl",
            type: "textarea",
            name: "Fetch-Url",
            props: {
              disabled: true,
            },
          });
          break;
        default:
          break;
      }
      return summaryItems;
    },
  },
  methods: {
    undoChanges() {
      this.newIntegration = cloneDeep(this.integration);
      this.$refs.form.resetValidation();
    },
    async saveChanges() {
      this.isLoading = true;
      try {
        await delay(500);
        await this.$store.dispatch("updateIntegration", this.newIntegration);
        this.newIntegration = cloneDeep(this.integration);
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
  },
};
</script>
